import React from 'react';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import withWrappers from 'hocs/ReactKeyProvider';

import { ShopFavorite } from '../interface';
import {
  AppBarStyled,
  TabLabelStyled,
  TabPanelStyled,
  TabStyled,
} from './styled';
import ProductSlider from './ProductSlider';

interface ShopFavoritesProps {
  favoritesBlock: ShopFavorite[];
}

const ShopFavorites = ({ favoritesBlock }: ShopFavoritesProps) => {
  const [value, setValue] = React.useState('2');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <AppBarStyled position="static" color="transparent" elevation={0}>
        <TabList
          centered
          onChange={handleChange}
          aria-label="shop favorites"
          indicatorColor="secondary"
        >
          {favoritesBlock.map((collection) => (
            <TabStyled
              key={collection.id}
              label={
                <TabLabelStyled
                  className="tracking-favorites-carousel-tab"
                  variant="body1"
                >
                  {collection.title}
                </TabLabelStyled>
              }
              value={collection.position.toString()}
            />
          ))}
        </TabList>
      </AppBarStyled>
      {favoritesBlock.map((collection) => (
        <TabPanelStyled
          key={collection.id}
          value={collection.position.toString()}
        >
          <ProductSlider
            favoriteProducts={collection.favorite_products?.sort(
              (a, b) => a.id - b.id,
            )}
          />
        </TabPanelStyled>
      ))}
    </TabContext>
  );
};

export default withWrappers(ShopFavorites);
