import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';

import Slider from 'react-slick';
import Product from 'pages/Homepage/ShopFavorites/Product';
import { down } from 'utils/breakpointHelpers';
import { Separator } from 'pages/shop/styled';

import {
  BodyStyled,
  ButtonStyled,
  CardsHeaderStyled,
  HeaderStyled,
  PageContainer,
  SliderStyled,
  SubHeaderStyled,
} from './styled';
import { EmptyStateProps } from './interface';

const EmptyState = ({ cartSuggestions }: EmptyStateProps) => {
  const matches = useMediaQuery(down('md'));

  return (
    <PageContainer>
      <HeaderStyled variant="h1" align="center">
        Your bag is empty.
      </HeaderStyled>
      <SubHeaderStyled variant="subtitle1" align="center" color="primary">
        Let’s change that
      </SubHeaderStyled>
      <BodyStyled variant="body1" align="center">
        We’re thrilled to have you here! Let’s fill your cart with something
        beautiful.
      </BodyStyled>
      <ButtonStyled href="/shop" color="primary" variant="contained">
        Take me to the flowers!
      </ButtonStyled>
      <Separator />
      <CardsHeaderStyled variant="h6">
        Some of our favorites...
      </CardsHeaderStyled>
      {matches ? (
        <SliderStyled>
          <Slider
            speed={500}
            infinite={false}
            initialSlide={1}
            slidesToScroll={1}
            centerMode
            centerPadding="24px"
            slidesToShow={1}
          >
            {cartSuggestions.map((favorite) => (
              <Product key={favorite.id} {...favorite} />
            ))}
          </Slider>
        </SliderStyled>
      ) : (
        <Grid container>
          {cartSuggestions.map((favorite) => (
            <Grid item sm={4} key={favorite.id}>
              <Product {...favorite} />
            </Grid>
          ))}
        </Grid>
      )}
    </PageContainer>
  );
};

export default EmptyState;
