/* eslint-disable import/extensions */
import { Controller } from '@hotwired/stimulus';
import 'slick-carousel/slick/slick.min.js';
import $ from 'jquery';

export default class extends Controller {
  static get targets() {
    return ['carousel'];
  }

  static get values() {
    return {
      speed: { type: Number, default: 4000 },
      enabled: { type: Boolean, default: true },
    };
  }

  connect() {
    if (this.enabledValue) {
      this.initializeCarousel();
    }
  }

  disconnect() {
    this.unmountCarousel();
  }

  initializeCarousel() {
    $(this.carouselTarget).slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      useTransform: false,
      autoplaySpeed: this.speedValue,
    });
  }

  unmountCarousel() {
    if ($(this.carouselTarget).hasClass('slick-initialized')) {
      $(this.carouselTarget).slick('unslick');
    }
  }
}
