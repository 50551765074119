import React from 'react';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { down } from 'utils/breakpointHelpers';
import { FavoriteProduct } from 'pages/Homepage/interface';
import Product from './Product';

const ProductSlider = ({
  favoriteProducts,
}: {
  favoriteProducts: FavoriteProduct[];
}) => {
  const matches = useMediaQuery(down('md'));

  return matches ? (
    <Slider
      speed={500}
      infinite={false}
      initialSlide={1}
      slidesToScroll={1}
      centerMode
      centerPadding="24px"
      slidesToShow={1}
    >
      {favoriteProducts.map((favoriteProduct) => (
        <Product key={favoriteProduct.id} {...favoriteProduct} />
      ))}
    </Slider>
  ) : (
    <Grid container>
      {favoriteProducts.map((favoriteProduct) => (
        <Grid item sm={4} key={favoriteProduct.id}>
          <Product {...favoriteProduct} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductSlider;
