import { AppBar, styled, Tab, Typography } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';

import { COLORS } from 'theme/colors';
import { down } from 'utils/breakpointHelpers';

export const AppBarStyled = styled(AppBar)({
  borderBottom: `0.7px solid ${COLORS.black20}`,
});

export const TabStyled = styled(Tab)({
  borderInline: '20px solid transparent',
  [down('sm')]: {
    borderInline: 'none',
  },
});

export const TabPanelStyled = styled(TabPanel)({
  padding: '22px 0 0',
  [down('md')]: {
    padding: '14px 0 0',
  },
});

export const TabLabelStyled = styled(Typography)({
  fontFamily: 'quincy-cf',
  fontWeight: 500,
  margin: 0,
  fontSize: 20,
  lineHeight: '24px',
  [down('md')]: {
    fontSize: 16,
    lineHeight: '20px',
  },
});
